/**
 * Created by osirvent on 26/04/2017.
 */
angular
    .module('annexaApp')
    .component('annexaDocumentSignatureCircuit', {
        templateUrl: './components/doc/annexa-document-signature-circuit/annexa-document-signature-circuit.html',
        controller: ['Language', function (Language) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();

            var signStates = [
                { id: 'PENDING', code: 'P', style: '' },
                { id: 'REJECTED', code: 'R', style: 'warning' },
                { id: 'FINALLY', code: 'F', style: 'success' },
                { id: 'IDLE', code: 'E', style: ''},
                { id: 'NONEED', code: 'N', style: 'success'}
            ]

            vm.getSignStateStyle = function(state) {
                var stateObj = $linq(signStates).singleOrDefault(undefined, "x => x.id == '" + state + "'");
                return stateObj ? stateObj.style : '';
            }

            vm.getUserName = function(user) {
                if(user) {
                	return user.name + ' ' + user.surename1 + (user.surename2 ? ' ' + user.surename2 : '');
                } else {
                	return '';
                }
            }
            
            vm.getPrintThird = function(third, thirdAddress) {
            	var name = '';
                if(third.corporateName) {
                    name = third.corporateName;
                } else {
                    name = third.name + ' ' + third.surename1;
                }

                if(third.surename2) {
                    name += ' ' + third.surename2;
                }
                
                if(thirdAddress && thirdAddress.telematicValue) {
                	name += ' (' + thirdAddress.telematicValue + ')';
                }
                
                return name;
            }

			this.$onInit = function() {
        	    if(vm.document && vm.document.signProcesses){
					_.forEach(vm.document.signProcesses,function(sp){
						if(sp.signProcessActions){
							_.forEach(sp.signProcessActions, function(act){
								if(act.additionalData){
									var obj = angular.fromJson(act.additionalData);
									if(obj){
										if(obj.SignerNumber && obj.SignerName){
											act.additionalData = obj.SignerNumber+" "+obj.SignerName;
										}else if(obj.SignerName){
											act.additionalData = obj.SignerName;
										}else if(obj.SignerNumber){
											act.additionalData = obj.SignerNumber;
										}
									}
								}
							});
						}
					});
				}
           }
        }],
        bindings: {
            document: '='
        }
    })