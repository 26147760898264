/**
 * Created by osirvent on 26/04/2017.
 */
angular
    .module('annexaApp')
    .component('annexaDocumentAudit', {
        templateUrl: './components/doc/annexa-document-audit/annexa-document-audit.html',
        controller: ['$rootScope', '$filter', '$scope', function ($rootScope, $filter, $scope) {
            var vm = this;

            vm.actionTypesList = [
                {id: '', name: 'global.literals.all'},
                {id: 'CREATED', name: 'global.documents.new.audit.actionType.CREATED'},
                {id: 'VIEWED', name: 'global.documents.new.audit.actionType.VIEWED'},
                {id: 'DOWNLOADED', name: 'global.documents.new.audit.actionType.DOWNLOADED'},
                {id: 'MODIFIED', name: 'global.documents.new.audit.actionType.MODIFIED'},
                {id: 'SIGN_START', name: 'global.documents.new.audit.actionType.SIGN_START'},
                {id: 'SIGNED', name: 'global.documents.new.audit.actionType.SIGNED'},
                {id: 'VALIDATED', name: 'global.documents.new.audit.actionType.VALIDATED'},
                {id: 'REFUSED', name: 'global.documents.new.audit.actionType.REFUSED'},
                {id: 'SENDMAIL', name: 'global.documents.new.audit.actionType.SENDMAIL'},
                {id: 'DRAFT', name: 'global.documents.new.audit.actionType.DRAFT'},
                {id: 'ENI', name: 'global.documents.new.audit.actionType.ENI'},
                {id: 'EXTERNAL_EDIT_DOCUMENT', name:'global.documents.new.audit.actionType.EXTERNAL_EDIT_DOCUMENT'},
                {id: 'ARCHIVED', name:'global.documents.new.audit.actionType.ARCHIVED'},
				{id: 'CHANGE_DOCUMENT_TYPE', name:'global.documents.new.audit.actionType.CHANGE_DOCUMENT_TYPE'},
				{id: 'REGENERATE_DOCUMENT', name:'global.documents.new.audit.actionType.REGENERATE_DOCUMENT'},
                {id: 'THIRD_SIGNED', name: 'global.documents.new.audit.actionType.THIRD_SIGNED'},
                {id: 'DEFINITIVE', name: 'global.documents.new.audit.actionType.DEFINITIVE'}
            ]

            vm.actionType = '';

            vm.filterAudit = function(item) {
                if(!item.id) {
                    vm.filterData = { objectType: 'DOCUMENT', objectId: vm.document.id };
                } else {
                    vm.filterData = { objectType: 'DOCUMENT', objectId: vm.document.id, action: item.id };
                }

                vm.tableDefinition.reloadInternalData(true, true);
            }
            
            $scope.$on('annexaDocumentAuditExecute', function (event, args) {
                if(vm.document && (args.id == vm.document.idPDFDocumentManager || args.id == vm.document.idDocumentManager)) {
                	vm.filterAudit({id:undefined});
                }
            });
            
            if(vm.document){
                vm.filterData = { objectType: 'DOCUMENT', objectId: vm.document.id };
            }

            var getFilterCall = function() {
                return vm.filterData;
            }

            var getFilterCallAux = function() {
                return {};
            }

            var actionRender = function(data, type, object, meta) {
                return $filter('translate')('global.documents.new.audit.actionType.' + data);
            };

            vm.getUserName = function(user) {
                if(user) {
                    return user.name + ' ' + user.surename1 + (user.surename2 ? ' ' + user.surename2 : '');
                }
            }

            vm.tableDefinition = {
                id: 'bottom-audit',
                lengthChange: false,
                compileHeader: false,
                showTopButtons: false,
                dom: 'rt<"row"<"col-sm-6"i><"col-sm-6"p>>',
                customDom: true,
                origin: 'common',
                objectType: 'Audit',
                sortName: 'actionDate',
                sort: [[1, 'desc']],
                filterCall: getFilterCall(),
                filterCallAux: getFilterCallAux(),
                filterCallFunc: getFilterCall,
                filterCallAuxFunc: getFilterCallAux,
                columns: [
                    { id: 'action', width: '30%', title: $filter('translate')('global.literals.action'), render: actionRender },
                    { id: 'actionDate', width: '40%', column: new DateTimeColumn($filter, 'global.literals.datetime') },
                    { id: 'userAction', width: '30%', column: new UserColumn($filter, 'global.literals.user') }
                ],
                containerScope: $scope
            }
        }],
        bindings: {
            document: '='
        }
    })

