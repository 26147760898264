/**
 * Created by osirvent on 17/03/2017.
 */
angular
    .module('annexaApp')
    .component('annexaDocumentsByTypeConfiguration', {
        templateUrl: './components/doc/annexa-documents-by-type-configuration/annexa-documents-by-type-configuration.html',
        controller: ['Language', 'RestService', 'AdminFactory', 'GlobalDataFactory', '$filter','AnnexaFormlyFactory', 'CommonAdminModals','CommonService', function(Language, RestService, AdminFactory, GlobalDataFactory, $filter, AnnexaFormlyFactory, CommonAdminModals,CommonService) {
            var vm = this;
            vm.orderTypes = [
            	{ id: 'asc', language1: $filter('translate')('global.literals.asc'), language2: $filter('translate')('global.literals.asc'), language3: $filter('translate')('global.literals.asc')},
                { id: 'desc', language1: $filter('translate')('global.literals.desc'), language2: $filter('translate')('global.literals.desc'), language3: $filter('translate')('global.literals.desc') }
            ];
            var normalColumns = [
                { id: 'docStatus', language1: 'global.literals.state', language2: 'global.literals.state', language3: 'global.literals.state', type: 'NORMAL', table: true, filter: false, folder: false },
                { id: 'favorite', language1: 'global.literals.favorite', language2: 'global.literals.favorite', language3: 'global.literals.favorite', type: 'NORMAL', table: true, filter: false, folder: false },
                { id: 'code', language1: 'global.literals.code', language2: 'global.literals.code', language3: 'global.literals.code', type: 'NORMAL', table: true, filter: true, folder: true },
                { id: 'name', language1: 'global.literals.title', language2: 'global.literals.title', language3: 'global.literals.title', type: 'NORMAL', table: true, filter: true, folder: false },
                { id: 'dossierTransactionDocuments', language1: 'global.literals.trams', language2: 'global.literals.trams', language3: 'global.literals.trams', type: 'NORMAL', table: true, filter: false, folder: false },
                { id: 'createUser', language1: 'global.literals.user', language2: 'global.literals.user', language3: 'global.literals.user', table: true, type: 'NORMAL', filter: true, folder: true },
                { id: 'modifyDate', language1: 'global.literals.modifyDate', language2: 'global.literals.modifyDate', language3: 'global.literals.modifyDate', type: 'NORMAL',table: true, filter: false, folder: true },
                { id: 'modifyDdate', language1: 'global.literals.modifyDate', language2: 'global.literals.modifyDate', language3: 'global.literals.modifyDate', type: 'NORMAL',table: false, filter: true, folder: false },
                { id: 'template', language1: 'global.literals.template', language2: 'global.literals.template', language3: 'global.literals.template', type: 'NORMAL', table: false, filter: true, folder: false },
                { id: 'section', language1: 'global.literals.section', language2: 'global.literals.section', language3: 'global.literals.section', type: 'NORMAL', table: false, filter: true, folder: false },
                { id: 'origin', language1: 'global.literals.module', language2: 'global.literals.module', language3: 'global.literals.module', type: 'NORMAL', table: false, filter: true, folder: false },
                { id: 'createdDate', language1: 'global.literals.creation_date', language2: 'global.literals.creation_date', language3: 'global.literals.creation_date', type: 'NORMAL',table: true, filter: false, folder: true },
                { id: 'digitalized', language1: 'global.documents.scanned', language2: 'global.documents.scanned', language3: 'global.documents.scanned', type: 'NORMAL',table: false, filter: true, folder: false },                
            ]
			
			var normalFilterColumns = [
	            { id: 'code', type: 'text', order: 0, label: 'global.literals.code' },
	            { id: 'name', type: 'text', order: 1, label: 'global.literals.title' },
	            { id: 'template', type: 'select', order: 2, label: 'global.literals.template', dataType: 'LOCAL', data: $linq(GlobalDataFactory.templates).orderBy("x => x." + Language.getActiveColumn()).toArray(), addAll: true, nameProperty: 'description' },
	            { id: 'section', type: 'select-tree', order: 3, label: 'global.literals.section', dataType: 'LOCAL', data: GlobalDataFactory.sections, addAll: true, nameProperty: Language.getActiveColumn() },
	            { id: 'type', type: 'select', order: 4, label: 'global.literals.type', dataType: 'LOCAL', data: $linq(GlobalDataFactory.documentTypes).where("x => x.isPublic").toArray(), addAll: true, nameProperty: Language.getActiveColumn() },
	            { id: 'origin', type: 'select', order: 5, label: 'global.literals.module', dataType: 'LOCAL', data: GlobalDataFactory.docModules, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true},
	            { id: 'createUser', type: 'text', order: 6, label: 'global.literals.user', callAux: true },
	            { id: 'modifyDdate', type: 'dateRange', order: 8, label: 'global.literals.modified', callAux: true },
	            { id: 'digitalized', type: 'checkbox', order: 9, label: 'global.documents.onlyScanned', callAux: true}
	        ]
            
			_.forEach(normalColumns, function (column, index) {
                Language.getAllTranslations(column.language1)
                    .then(function (data) {
                        normalColumns[index].language1 = data.language1;
                        normalColumns[index].language2 = data.language2;
                        normalColumns[index].language3 = data.language3;
                });
            });

            vm.loading = true;

            vm.languageColumn = Language.getActiveColumn();

            if(!vm.model) {
                vm.model = []
            }

            var initializeTableColumnsList = function (customFields) {
                var columnsList = [];

                var list = $linq(normalColumns).where("x => x.table").toArray();

                _.forEach(list, function (column) {
                    columnsList.push(column);
                });

                _.forEach(customFields, function (customField) {
                    var custField = { id: customField.id, language1: customField.language1, language2: customField.language2, langauge3: customField.language3, type: 'CUSTOM', table: true, filter: true, folder: true };
                    columnsList.push(customField);
                });

                return columnsList;
            };

            var initializeFilterColumnsList = function (customFields) {
                var columnsList = [];

                var list = $linq(normalColumns).where("x => x.filter").toArray();

                _.forEach(list, function (column) {
                    columnsList.push(column);
                });

                _.forEach(customFields, function (customField) {
                    var custField = { id: customField.id, language1: customField.language1, language2: customField.language2, langauge3: customField.language3, type: 'CUSTOM', table: true, filter: true, folder: true };
                    columnsList.push(customField);
                });

                return columnsList;
            };

            var initializeFolderColumnsList = function(customFields) {
                var columnsList = [];

                var list = $linq(normalColumns).where("x => x.folder").toArray();

                _.forEach(list, function (column) {
                    columnsList.push(column);
                });

                _.forEach(customFields, function (customField) {
                    var custField = { id: customField.id, language1: customField.language1, language2: customField.language2, langauge3: customField.language3, type: 'CUSTOM', table: true, filter: true, folder: true };
                    columnsList.push(customField);
                });

                return columnsList;
            };

            vm.selectedDocType = undefined;
            vm.documentTypes = [];
            vm.iconTypes = [
                { id: 'AWESOME', description: 'Awesome' },
                { id: 'MATERIALICONS', description: 'Material Design' }
            ]

            var types = GlobalDataFactory.documentTypes;

            var types = $linq(types).select(function(x) {
                var customFields = $linq(x.customFields).select("x => x.customField").toArray();

                return { id: x.id, language1: x.language1, language2: x.language2, language3: x.language3, customFields: customFields }
            }).toArray();

            var configuredTypes = $linq(vm.model).select("x => x.type").toArray();
            var selectableTypes = types;

            if(configuredTypes.length != 0) {
                selectableTypes = $linq(types).except(configuredTypes, "(x,y) => x.id == y.id").toArray();
            }


            vm.documentTypes = selectableTypes;

            if(vm.model) {
                _.forEach(vm.model, function (item, index) {
                    var cType = $linq(types).singleOrDefault(undefined, "x => x.id == " + item.type.id);
                    if(cType) {
                        var custom
                        vm.model[index].tableColumnsList = initializeTableColumnsList(cType.customFields);
                        vm.model[index].filterColumnsList = initializeFilterColumnsList(cType.customFields);
                        vm.model[index].folderColumnsList = initializeFolderColumnsList(cType.customFields);
                        if(vm.model[index].orderBy){
                        	var orderBy = $linq(vm.model[index].tableColumnsList).singleOrDefault({"id":vm.model[index].orderBy}, "x => x.id == '" + vm.model[index].orderBy+"'");
                            if(orderBy) {
                            	vm.model[index].orderBy = orderBy;
                            }
                        }
                        if(vm.model[index].orderType){
                        	var orderType = $linq(vm.orderTypes).singleOrDefault({"id":vm.model[index].orderType}, "x => x.id == '" + vm.model[index].orderType+"'");
                            if(orderType) {
                            	vm.model[index].orderType = orderType;
                            }
                        }
                    }
                });

            }

            vm.loading = false;

            vm.removeType = function(index) {
                var type = vm.model[index].type;

                vm.model.splice(index, 1);

                vm.documentTypes.push(type);
            };

            vm.addType = function() {
                if(vm.selectedDocType) {
                    vm.model.push({
                        type: vm.selectedDocType,
                        showList: true,
                        showFolders: true,
                        iconType: { id: 'AWESOME', description: 'Awesome' },
                        icon: '',
                        tableColumns: [],
                        filterColumns: [],
                        folderColumns: [],
						predefinedFilters:[],
                        tableColumnsList: initializeTableColumnsList(vm.selectedDocType.customFields),
                        filterColumnsList: initializeFilterColumnsList(vm.selectedDocType.customFields),
                        folderColumnsList: initializeFolderColumnsList(vm.selectedDocType.customFields)
                    });

                    var selectedDocTypeIndex = $linq(vm.documentTypes).indexOf("x => x.id == " + vm.selectedDocType.id);
                    vm.selectedDocType = undefined;
                    if(selectedDocTypeIndex != -1) {
                        vm.documentTypes.splice(selectedDocTypeIndex, 1);
                    }
                }
            }

			vm.modifyPredefinedFilters = function(typeId){
				var language = Language.getActiveColumn();
				var fields = [];
				var data = {row: true,colClass: ' col-sm-12',labelClass: 'label-strong'};
        		var type = $linq(vm.model).firstOrDefault(undefined,"x => x.type && x.type.id == "+typeId);
				var cType = undefined;
				if(type){
					cType = $linq(types).firstOrDefault(undefined, "x => x.id == " + type.type.id);
				}
				if(type && cType){
					var modal = angular.copy(CommonAdminModals.updatePredefinedFilterModal);
	                modal.annexaFormly = new AnnexaFormly();
	                modal.annexaFormly.addGroup('modal_body','modal-body p-lg',fields);
	                modal.annexaFormly.model = {};
					modal.annexaFormly.model.modal_body = {};
	                modal.annexaFormly.options = {};
	                modal.annexaFormly.options.formState = {readOnly: false};
	                modal.alerts = [];
					if(type.filterColumns){
						_.forEach(type.filterColumns, function(filter){
							var filterColumn = undefined;
							if(normalFilterColumns){
								filterColumn = $linq(normalFilterColumns).firstOrDefault(undefined, "x => x.id == '"+filter.id+"'");
							}
							if(cType.customFields && !filterColumn){
								filterColumn = $linq(cType.customFields).firstOrDefault(undefined, "x => x.id == "+filter.id);
							}
							if(filterColumn){
								if((filterColumn.type === 'text' || ((filterColumn.backendType === 'STRING' || filterColumn.backendType === 'INTEGER' || filterColumn.backendType === 'DECIMAL'|| filterColumn.backendType === 'ADDRESS') && (filterColumn.frontendType === 'INPUT' || filterColumn.frontendType === 'JSON')) || filterColumn.frontendType === 'TEXTAREA') && filter.id){
									if(type.predefinedFilters){
										var predefFilter = $linq(type.predefinedFilters).firstOrDefault(undefined, "x => x.name == '"+filter.id+"'");
										modal.annexaFormly.model.modal_body[filter.id] = ((predefFilter && predefFilter.value)?predefFilter.value:undefined);
										modal.annexaFormly.model.modal_body[filter.id+'_negated'] = ((predefFilter.negated === 'true')?true:false);
									}else{
										modal.annexaFormly.model.modal_body[filter.id] = undefined;
										modal.annexaFormly.model.modal_body[filter.id+'_negated'] = false;
									}
									var field = modal.annexaFormly.createField(filter.id,'annexaInputRow','col-sm-5',new AnnexaFormlyFieldTemplateOptions('text',((filterColumn.label)?filterColumn.label:filterColumn[vm.languageColumn]),false,false,undefined,255),data);                
									fields.push(field);
									var field = modal.annexaFormly.createField(filter.id+'_negated','annexaCheckbox','col-sm-1',{type: 'checkbox', label: 'global.literals.no', required:false},data);                
									fields.push(field);															
								}else if((filterColumn.type === 'checkbox' || filterColumn.frontendType === 'CHECKBOX') && filter.id){
									if(type.predefinedFilters){
										var predefFilter = $linq(type.predefinedFilters).firstOrDefault(undefined, "x => x.name == '"+filter.id+"'");
										modal.annexaFormly.model.modal_body[filter.id] = ((predefFilter && predefFilter.value === 'true')?true:undefined);
									}else{
										modal.annexaFormly.model.modal_body[filter.id] = undefined;
									}
									var field = modal.annexaFormly.createField(filter.id,'annexaCheckbox','col-sm-6 p-t',{type: 'checkbox', label: ((filterColumn.label)?filterColumn.label:filterColumn[vm.languageColumn]), required:false},data);                
									fields.push(field);								
								}else if((filterColumn.type === 'dateRange' || filterColumn.backendType === 'DATETIME' ) && filter.id){
									var dates = undefined;
									if(type.predefinedFilters){
										var predefFilter = $linq(type.predefinedFilters).firstOrDefault(undefined, "x => x.name == '"+filter.id+"'");
										if(predefFilter && predefFilter.value){
											try{
												dates = predefFilter.value.split(';');
											}catch(e){
												dates = undefined;
											}
											var isDateTo = false;
											if(dates && dates.length > 1 && dates[1]) {
												var dateAux = dates[1];
												dateAux = dateAux.split('/');
												if(dates[0] == 'dataFrom'){
													if(dateAux && dateAux.length == 3){
														var fromDate = new Date(Date.UTC(dateAux[2],dateAux[1]-1,dateAux[0],00,00,00));
						                            	if(fromDate != "Invalid Date") {
						                            		modal.annexaFormly.model.modal_body[filter.id+'From'] = $filter('date')(fromDate, 'dd/MM/yyyy');
						                            	} else {
						                            		modal.annexaFormly.model.modal_body[filter.id+'From'] = dates[1];
						                            	}
													}else{
														modal.annexaFormly.model.modal_body[filter.id+'From'] = undefined;
													}
												}else{
													isDateTo = true;
													if(dateAux && dateAux.length == 3){
														var toDate = new Date(Date.UTC(dateAux[2],dateAux[1]-1,dateAux[0],21,59,59));
						                            	if(toDate != "Invalid Date") {
						                            		modal.annexaFormly.model.modal_body[filter.id+'To'] = $filter('date')(toDate, 'dd/MM/yyyy');
						                            	} else {
						                            		modal.annexaFormly.model.modal_body[filter.id+'To'] = dates[1];
						                            	}
													}else{
														modal.annexaFormly.model.modal_body[filter.id+'To'] = undefined;
													}
												}
					                        }else{
												modal.annexaFormly.model.modal_body[filter.id+'From'] = undefined;
											}
					
					                        if(dates && dates.length > 3 && dates[3]) {
					                            var dateAux = dates[3];
												dateAux = dateAux.split('/');
					                            if(dateAux && dateAux.length == 3){
					                            	var toDate = new Date(Date.UTC(dateAux[2],dateAux[1]-1,dateAux[0],21,59,59));
					                            	if(toDate != "Invalid Date") {
					                            		modal.annexaFormly.model.modal_body[filter.id+'To'] = $filter('date')(toDate, 'dd/MM/yyyy');
					                            	} else {
					                            		modal.annexaFormly.model.modal_body[filter.id+'To'] = dates[3];
					                            	}
												}else{
													modal.annexaFormly.model.modal_body[filter.id+'To'] = undefined;
												}
					                        }else{
												if(!isDateTo){
													modal.annexaFormly.model.modal_body[filter.id+'To'] = undefined;	
												}
											}
										}else{
											modal.annexaFormly.model.modal_body[filter.id+'From'] = undefined;
											modal.annexaFormly.model.modal_body[filter.id+'To'] = undefined;
										}
										if(predefFilter){
											modal.annexaFormly.model.modal_body[filter.id+'_negated'] = ((predefFilter.negated === 'true')?true:false);
										}else{
											modal.annexaFormly.model.modal_body[filter.id+'_negated'] = false;
										}
									}else{
										modal.annexaFormly.model.modal_body[filter.id+'From'] = undefined;
										modal.annexaFormly.model.modal_body[filter.id+'To'] = undefined;
										modal.annexaFormly.model.modal_body[filter.id+'_negated'] = false;
									}
									var onChangeDataPickerMask = function(key) {
										modal.annexaFormly.model.modal_body[key] = $filter('date')(modal.annexaFormly.model.modal_body[key + "Aux"], 'dd/MM/yyyy');
                                	}
									var field = modal.annexaFormly.createField(filter.id+'From','annexaDatepickerMaskRow','col-sm-3',new AnnexaFormlyFieldDatepickerTemplateOptions('text',((filterColumn.label)?filterColumn.label:filterColumn[vm.languageColumn]),false,{format: 'dd/MM/yyyy',initDate:undefined,showWeeks: false,startingDay: 1}, filter.id+'FromAux', onChangeDataPickerMask),data);                
									fields.push(field);
									field = modal.annexaFormly.createField(filter.id+'To','annexaDatepickerMaskRow','col-sm-2',new AnnexaFormlyFieldDatepickerTemplateOptions('text','global.literals.dateTo',false,{format: 'dd/MM/yyyy',initDate:undefined,showWeeks: false,startingDay: 1}, filter.id+'ToAux', onChangeDataPickerMask),data);                
									fields.push(field);
									var field = modal.annexaFormly.createField(filter.id+'_negated','annexaCheckbox','col-sm-1',{type: 'checkbox', label: 'global.literals.no', required:false},data);                
									fields.push(field);									
								}else if((filterColumn.type === 'select' || filterColumn.frontendType === 'SELECT' || filterColumn.frontendType === 'RADIO' || filterColumn.frontendType === 'SELECT_LINKED')&& filter.id){
									var list = [];
									if(filterColumn.data){
										list = angular.copy(filterColumn.data);
									}
									if(type.predefinedFilters){
										var predefFilter = $linq(type.predefinedFilters).firstOrDefault(undefined, "x => x.name == '"+filter.id+"'");
										if(predefFilter && predefFilter.value){
											var selected = $linq(list).firstOrDefault(undefined,"x => x.id+'' == '"+predefFilter.value+"'");
											if(selected && filterColumn.nameProperty && filterColumn.nameProperty != 'id' && filterColumn.nameProperty != 'language1' && filterColumn.nameProperty != 'language2' && filterColumn.nameProperty != 'language3'){
												selected[filterColumn.nameProperty] = $filter('translate')(selected[filterColumn.nameProperty]);
											}
											modal.annexaFormly.model.modal_body[filter.id] = ((selected)?selected:undefined);	
										}else{
											modal.annexaFormly.model.modal_body[filter.id] = undefined;
										}
										if(predefFilter){
											modal.annexaFormly.model.modal_body[filter.id+'_negated'] = ((predefFilter.negated === 'true')?true:false);
										}else{
											modal.annexaFormly.model.modal_body[filter.id+'_negated'] = false;
										}
									}else{
										modal.annexaFormly.model.modal_body[filter.id] = undefined;
										modal.annexaFormly.model.modal_body[filter.id+'_negated'] = false;
									}
									var field = modal.annexaFormly.createField(filter.id, 'annexaSelectRow', 'col-sm-5', new AnnexaFormlyFieldSelectTemplateOptions(((filterColumn.label)?filterColumn.label:filterColumn[vm.languageColumn]),'id',((filterColumn.nameProperty)?filterColumn.nameProperty:vm.languageColumn),list,false),data);
									fields.push(field);
									var field = modal.annexaFormly.createField(filter.id+'_negated','annexaCheckbox','col-sm-1',{type: 'checkbox', label: 'global.literals.no', required:false},data);                
									fields.push(field);										
								}else if((filterColumn.type === 'select-multiple' || filterColumn.frontendType === 'MULTIPLESELECT') && filter.id){
									var list = [];
									if(filterColumn.data){
										list = angular.copy(filterColumn.data);
									}
									if(type.predefinedFilters){
										var predefFilter = $linq(type.predefinedFilters).firstOrDefault(undefined, "x => x.name == '"+filter.id+"'");
										if(predefFilter && predefFilter.value){
											var selected = [];
											try{
												var selectedList = predefFilter.value.split(';');
												if(selectedList && selectedList.length > 0){
													selected =  $linq(list).intersect(selectedList, function(x,y){
														if(y && (y.id || y.id === false) && (y.id+"") == x ){
															return true;
														}else{
															return false;
														}
													}).toArray(); 
												}
											}catch(e){}
											if(selected && selected.length > 0){
												_.forEach(selected, function(sel){
													if(filterColumn.nameProperty && filterColumn.nameProperty != 'id' && filterColumn.nameProperty != 'language1' && filterColumn.nameProperty != 'language2' && filterColumn.nameProperty != 'language3'){
														sel[filterColumn.nameProperty] = $filter('translate')(sel[filterColumn.nameProperty]);
													}
												});
											}
											modal.annexaFormly.model.modal_body[filter.id] = ((selected)?selected:undefined);	
										}else{
											modal.annexaFormly.model.modal_body[filter.id] = undefined;
										}
										if(predefFilter){
											modal.annexaFormly.model.modal_body[filter.id+'_negated'] = ((predefFilter.negated === 'true')?true:false);
										}else{
											modal.annexaFormly.model.modal_body[filter.id+'_negated'] = false;
										}
									}else{
										modal.annexaFormly.model.modal_body[filter.id] = undefined;
										modal.annexaFormly.model.modal_body[filter.id+'_negated'] = false;
									}
									var field = modal.annexaFormly.createField(filter.id, 'annexaMultipleSelectRow', 'col-sm-6', new AnnexaFormlyFieldSelectTemplateOptions(((filterColumn.label)?filterColumn.label:filterColumn[vm.languageColumn]), 'id',((filterColumn.nameProperty)?filterColumn.nameProperty:vm.languageColumn), list, false),data);
									fields.push(field);		
									var field = modal.annexaFormly.createField(filter.id+'_negated','annexaCheckbox','col-sm-1',{type: 'checkbox', label: 'global.literals.no', required:false},data);                
									fields.push(field);				
								}else if(filterColumn.type === 'select-tree' && filter.id){
									var list = [];
									if(filterColumn.data){
										var listAux = angular.copy(filterColumn.data);
										list =  CommonService.getTreeData(listAux, vm.languageColumn);
									}
									if(type.predefinedFilters){
										var predefFilter = $linq(type.predefinedFilters).firstOrDefault(undefined, "x => x.name == '"+filter.id+"'");
										if(predefFilter && predefFilter.value){
											var selected = $linq(listAux).firstOrDefault(undefined,"x => x.id+'' == '"+predefFilter.value+"'");
											if(selected && filterColumn.nameProperty && filterColumn.nameProperty != 'id' && filterColumn.nameProperty != 'language1' && filterColumn.nameProperty != 'language2' && filterColumn.nameProperty != 'language3'){
												selected[filterColumn.nameProperty] = $filter('translate')(selected[filterColumn.nameProperty]);
											}
											var title = ((filterColumn.nameProperty)?filterColumn.nameProperty:vm.languageColumn);
											title = ((selected && selected[title])?selected[title]:'id');
											modal.annexaFormly.model.modal_body[filter.id] = {$selected:((selected)?{id:selected.id,title:title}:undefined)};
										}else{
											modal.annexaFormly.model.modal_body[filter.id] = {$selected:undefined};
										}
										if(predefFilter){
											modal.annexaFormly.model.modal_body[filter.id+'_negated'] = ((predefFilter.negated === 'true')?true:false);
										}else{
											modal.annexaFormly.model.modal_body[filter.id+'_negated'] = false;
										}
									}else{
										modal.annexaFormly.model.modal_body[filter.id] = {$selected:undefined};
										modal.annexaFormly.model.modal_body[filter.id+'_negated'] = false;
									}
									var field = modal.annexaFormly.createField(filter.id, 'annexaSelectTreeRow', 'col-sm-5', new AnnexaFormlyFieldSelectTemplateOptions(((filterColumn.label)?filterColumn.label:filterColumn[vm.languageColumn]),'id',((filterColumn.nameProperty)?filterColumn.nameProperty:vm.languageColumn),list,false, undefined, undefined, undefined, true),data);
									fields.push(field);	
									var field = modal.annexaFormly.createField(filter.id+'_negated','annexaCheckbox','col-sm-1',{type: 'checkbox', label: 'global.literals.no', required:false},data);                
									fields.push(field);							
								}
							}
						});
					}
					var updatePredefinedFilters = function(){
						var self = this;
						if(self && self.annexaFormly && self.annexaFormly.model && self.annexaFormly.model.modal_body && type && cType && type.filterColumns){
							var model = self.annexaFormly.model.modal_body;
							if(type.predefinedFilters){
								type.predefinedFilters.length = 0;
							}else{
								type.predefinedFilters = [];
							} 
							_.forEach(type.filterColumns, function(filter){
								var filterColumn = undefined;
								var predefinedFilter = {name:filter.id+""};
								if(normalFilterColumns){
									filterColumn = $linq(normalFilterColumns).firstOrDefault(undefined, "x => x.id == '"+filter.id+"'");
								}
								if(cType.customFields && !filterColumn){
									filterColumn = $linq(cType.customFields).firstOrDefault(undefined, "x => x.id == "+filter.id);
								}
								if((filterColumn.type === 'text' || ((filterColumn.backendType === 'STRING' || filterColumn.backendType === 'INTEGER' || filterColumn.backendType === 'DECIMAL'|| filterColumn.backendType === 'ADDRESS') && (filterColumn.frontendType === 'INPUT' || filterColumn.frontendType === 'JSON')) || filterColumn.frontendType === 'TEXTAREA') && filterColumn.id){
									if(model[filter.id]){
										predefinedFilter.value = model[filter.id];
									}else{
										predefinedFilter.value = "";
									}
									predefinedFilter.negated = ((model[filter.id+"_negated"])?"true":"false");		
									type.predefinedFilters.push(predefinedFilter);															
								}else if((filterColumn.type === 'checkbox' || filterColumn.frontendType === 'CHECKBOX') && filter.id){
									if(model[filter.id]){
										predefinedFilter.value = model[filter.id]+"";
									}else{
										predefinedFilter.value = "";
									}								
									type.predefinedFilters.push(predefinedFilter);
								}else if((filterColumn.type === 'dateRange' || filterColumn.backendType === 'DATETIME' ) && filter.id){
									if(model[filter.id+"From"] || model[filter.id+"To"]){
										var dateAux = "";
										var dateFromAux = "";
										var dateToAux = "";
										predefinedFilter.value = "";
										if(model[filter.id+"From"]){
				                            if(model[filter.id+"From"] instanceof Date) {
				                            	dateFromAux = $filter('date')(model[filter.id+"From"], 'dd/MM/yyyy');
				                            } else {
				                            	dateFromAux = model[filter.id+"From"];
				                            }
											predefinedFilter.value += "dataFrom;"+dateFromAux;
										}
										if(model[filter.id+"To"]){
											if(model[filter.id+"To"] instanceof Date) {
				                            	dateToAux = $filter('date')(model[filter.id+"To"], 'dd/MM/yyyy');
				                            } else {
				                            	dateToAux = model[filter.id+"To"];
				                            }
											predefinedFilter.value += ((model[filter.id+"From"])?";":"")+"dataTo;"+dateToAux;
										}	
									}else{
										predefinedFilter.value = "";
									}							
									predefinedFilter.negated = ((model[filter.id+"_negated"])?"true":"false");		
									type.predefinedFilters.push(predefinedFilter);
								}else if((filterColumn.type === 'select' || filterColumn.frontendType === 'SELECT' || filterColumn.frontendType === 'RADIO' || filterColumn.frontendType === 'SELECT_LINKED')&& filter.id){
									if(model[filter.id]){
										if(model[filter.id].id){
											predefinedFilter.value = model[filter.id].id+"";
										}else{
											predefinedFilter.value = model[filter.id]+"";
										}
									}else{
										predefinedFilter.value = "";
									}	
									predefinedFilter.negated = ((model[filter.id+"_negated"])?"true":"false");		
									type.predefinedFilters.push(predefinedFilter);										
								}else if((filterColumn.type === 'select-multiple' || filterColumn.frontendType === 'MULTIPLESELECT') && filter.id){
									if(model[filter.id] && model[filter.id].length > 0){
										var list = "";
										_.forEach(model[filter.id], function(val){
											if(val && val.id){
												list += ((list === "")?'':";")+val.id;
											}else{
												list += ((list === "")?"":";")+val;
											}
										});
										predefinedFilter.value = list;
									}else{
										predefinedFilter.value = "";
									}				
									predefinedFilter.negated = ((model[filter.id+"_negated"])?"true":"false");		
									type.predefinedFilters.push(predefinedFilter);
								}else if(filterColumn.type === 'select-tree' && filter.id){
									if(model[filter.id] && model[filter.id].$selected){
										if(model[filter.id].$selected.id){
											predefinedFilter.value = model[filter.id].$selected.id+"";
										}else{
											predefinedFilter.value = model[filter.id].$selected+"";
										}
									
									}else{
										predefinedFilter.value = "";
									}						
									predefinedFilter.negated = ((model[filter.id+"_negated"])?"true":"false");		
									type.predefinedFilters.push(predefinedFilter);
								}
							});
							self.close();
						}else{
							self.alerts.push({ msg: $filter('translate')('global.errors.unknownUpdate') });
						}
					}
					AnnexaFormlyFactory.showModal("modalUpdatePredefinedFilterModal", modal, updatePredefinedFilters, false);
				}
			}
        }],
        bindings: {
            model: '=',
            form: '='
        }
    });