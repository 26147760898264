/**
 * Created by osirvent on 24/04/2017.
 */
angular
    .module('annexaApp')
    .component('annexaDocument', {
        templateUrl: './components/doc/annexa-document/annexa-document.html',
        controller: ['$rootScope', 'DccumentsFactory', 'BoxFactory', 'globalModals', 'AnnexaFormlyFactory', 'Upload', '$state', '$scope', 'SignLocalFactory', '$filter', 'GlobalDataFactory', 'AnnexaPermissionsFactory', 'Language', 'HelperService','DialogsFactory', 
        function($rootScope, DccumentsFactory, BoxFactory, globalModals, AnnexaFormlyFactory, Upload, $state, $scope, SignLocalFactory, $filter, GlobalDataFactory, AnnexaPermissionsFactory, Language, HelperService,DialogsFactory) {
            var vm = this;
            vm.objectParent = "DOCUMENT";
            //region Actions

            vm.sendToSign = function() {
                vm.oldDocumentContent = vm.document.content;
                SignLocalFactory.sendToSignModal(undefined, [vm.document]);
            }

            vm.seeDocument = function(idDocumentManager) {
                if(!idDocumentManager) {
                    idDocumentManager = vm.document.currentDocument;
                }

                if(idDocumentManager) {
                    $rootScope.loading(true);
                    var windowReference = window.open();
                    DccumentsFactory.getDocContent(vm.document.id)
                        .then(function(data) {
                        	$rootScope.loading(false);
                        	windowReference.location = data;
                    }).catch(function (error) {
                        $rootScope.loading(false);
                        windowReference.close();
                    });
                }
            }

            vm.replaceDocumentModal = function() {
                var replaceDocument = function() {
                    if(this.annexaFormly.form.$valid) {
                        $rootScope.loading(true)
                        var self = this;
                        var data = this.annexaFormly.model.modal_body;
                        var customFields = vm.document.customFields;
                        var archiveClassification = vm.document.archiveClassification;

                        DccumentsFactory.updateDocumentFile(data.file, vm.document.id)
                            .then(function (data) {
                                vm.document = data;
                                vm.document.archiveClassification = !archiveClassification ? {} : archiveClassification;
                                vm.document.customFields = customFields;
                                vm.document.content = data.metadataSearch;
                                $rootScope.$broadcast('docContentLoaded');
                                $rootScope.loading(false);
                                self.close();
                            }).catch(function(error) {
                                $rootScope.loading(false);
                        });
                    }
                }

                var modal = angular.copy(globalModals.uploadFile);

                if(vm.document.docStatus == 'NEW'){
                    modal.title = 'global.literals.attachDocument';
                }else{
                    modal.title = 'global.documents.new.replaceDocument';
                }

                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};
                AnnexaFormlyFactory.showModal('modalAddAttachment', modal, replaceDocument, false);
            }

            vm.completeDocument = function(){
                $rootScope.loading(true);
                DccumentsFactory.definitiveDocument({id: vm.document.id})
                    .then(function (data) {
                        $state.transitionTo('annexa.doc.documents.all');
                        $rootScope.loading(false);
                    }).catch(function (error) {
	                    $rootScope.loading(false);
						if(error && error.message === "Is not valid document from template not found"){
							DialogsFactory.error($filter('translate')('global.documents.definitiveNoValidError', {name:doc.name}));
						}else{
							DialogsFactory.error($filter('translate')('global.documents.definitiveError', {name:doc.name}));
						}
                });
            };

            //endregion

            //region General

            vm.dossierModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.tram'").toArray();

            vm.document = DccumentsFactory.document;
            if(vm.document && !vm.document.allRelatedDocuments){
            	vm.document.allRelatedDocuments = [];
            }
            vm.document.content = '';
            vm.canEdit = DccumentsFactory.canEdit(vm.document, DccumentsFactory.isArchivedDossier);
            vm.canEditTitle = DccumentsFactory.canEditTitle(vm.canEdit, DccumentsFactory.isArchivedDossier);
            vm.isEditing = undefined;
            vm.alerts = [];
            vm.editingDocument = false;
            vm.esetMode = $rootScope.esetMode;
            vm.fileTypeDesc = '';
            vm.isAuthenticCopy = DccumentsFactory.isAuthenticCopy;
            vm.isArchivedDossier = DccumentsFactory.isArchivedDossier;
            vm.documentPublications = DccumentsFactory.publishAuditInfo;
            //endregion

            //region Boxes
            var addDocumentSave = function(newRelatedDocument) {
                $rootScope.loading(true);
                
                DccumentsFactory.newRelatedDocument(vm.document.id, newRelatedDocument)
                    .then(function(data) {
                    	vm.documentBox.content.length =0;
                    	_.forEach(data.allRelatedDocuments, function(doc){
                    		 vm.documentBox.content.push(doc);
                    	});
                    	$rootScope.$broadcast('annexaBoxDocumentsUpdateCounter', { content: vm.documentBox.content, relationType: 'DOCUMENT'});
                        $rootScope.loading(false);
                    }).catch(function (error) {
                        $rootScope.loading(false);
                        console.error(error);
                        if (error.data && error.data.message) {
                        	vm.alerts.push({ msg: error.data.message });
                        } else {
                        	vm.alerts.push({ msg: 'global.errors.errorCreateDocument' });
                        }
                });
            }

            $scope.$on('annexaBoxDocumentsUpdateContentENI', function(event, args){ 
            	if(args.controller && args.controller == 'DocumentController' && args.content && vm.documentBox && vm.documentBox.isEdit ) {
            		vm.documentBox.content.length =0;
                	_.forEach(args.content, function(doc){
                		 vm.documentBox.content.push(doc);
                	});
                	$rootScope.$broadcast('annexaBoxDocumentsUpdateCounter', { content: vm.documentBox.content, relationType: 'INTERNAL_DOCUMENT'});
            	}
            });
            
            $scope.$on('annexaBoxDocumentsDelete', function (event, args) {
                if(args.origin && args.origin == 'annexa-document-box' && vm.documentBox.isEdit) {
                	 DccumentsFactory.deleteRelatedDocument(vm.document.id, args.removedId)
                        .then(function () {
                            //Empty
                        }).catch(function(error) {
                            console.error(error);
                    });
                }
            });
            
            var getEditPermissions = function(type) {
                var permissions = [];
            	if(vm.document && vm.document.profiles && vm.document.profiles.length > 0){
                    if(AnnexaPermissionsFactory.haveSinglePermissionDual('view_documents',vm.document.profiles)) {
                        permissions.push('view_documents');
                    }

                    if(AnnexaPermissionsFactory.haveSinglePermissionDual('new_document',vm.document.profiles)) {
                        permissions.push('new_document');
                    }    
            	}
                
                if (permissions.length > 0) {                	               
	                switch (type) {
	                    case 'thirds':
	                        return { new: 'new_third', view: 'new_document' };
	                        break;
	                    case 'registerEntries':
	                    	return { view: ['view_output_register', 'view_all_output_register'] };
	                        break;
	                    case 'sessions':
	                    	return { view: ['view_sessions', 'view_sessions'] };
	                        break;
	                    default:     
	                    	return permissions;
	                        break;
	                }
                } else {
                	return type ? {}:[];
                }
                
            }
            
            vm.documentBox = {
            		boxTitle: 'global.books.view.relatedDocuments',
                    permissions: {new: 'new_document', view: 'view_documents'},
                    search: {
                        placeholder: 'global.literals.placeholser_search_documents',
                        advanced: true,
                        saveSearch: addDocumentSave,
                        addAfter: true,
                        additionalFilter: {documentBoxNormalSearch: true}
                    },
                    content: vm.document.allRelatedDocuments,
                    new: { multiple: { scanner: true } },
                    config: {},
                    origin: 'annexa-document-box',
                    isEdit:true
                }
            
            var addRegisterEntrySave = function(newRegEntries) {
            	var newRegEntryIds = [];
            	if(newRegEntries && newRegEntries.length > 0){
            		newRegEntryIds = $linq(newRegEntries).select("x=>x.id").toArray();
            	}
            	DccumentsFactory.newDocumentRegisterEntry(vm.document.id, newRegEntryIds )
                    .then(function(data) {
                    	if(data){
	                    	if (!vm.registerEntriesBox.content) {
	                    		vm.registerEntriesBox.content = [];
	                    	}else{
	                    		vm.registerEntriesBox.content.length = 0;
	                    	}
	                    	_.forEach(data, function(th){
	                    		vm.registerEntriesBox.content.push(th);
	                    	});
                    	}
                }).catch(function (error) {
                    console.error(error)
                });
            }
            $scope.$on('annexaBoxRegisterEntriesDelete', function (event, args) {
                if(args.origin && args.origin == 'document' && vm.document && vm.document.profiles && AnnexaPermissionsFactory.haveSinglePermissionDual('view_documents',vm.document.profiles)) {
                	DccumentsFactory.deleteDocumentRegisterEntry(vm.document.id,  args.removedId)
                    .then(function(data) {
                    	if(data){
	                    	if (!vm.registerEntriesBox.content) {
	                    		vm.registerEntriesBox.content = [];
	                    	}else{
	                    		vm.registerEntriesBox.content.length = 0;
	                    	}
	                    	_.forEach(data, function(th){
	                    		vm.registerEntriesBox.content.push(th);
	                    	});
                    	}
                }).catch(function (error) {
                    console.error(error)
                });
                }
            });
            vm.registerEntriesBox = {
                boxTitle: 'global.literals.relatedRegisterEntries',
                permissions: getEditPermissions('registerEntries'),
                search: { placeholder: 'global.literals.placeholser_search_registerEntry', 
                	advanced: true, 
                	saveSearch: ((vm.document && vm.document.profiles && AnnexaPermissionsFactory.haveSinglePermissionDual('view_documents',vm.document.profiles)) ? addRegisterEntrySave : undefined) 
                },
                new: {},
                content:  DccumentsFactory.documentRegisterEntries ?
                    $linq(DccumentsFactory.documentRegisterEntries).select(function(x) {
                        return {
                            id: x.id,
                            entryType: x.entryType,
                            entryNumber: x.entryNumber,
                            originEntryNumber: x.originEntryNumber,
                            extract: x.extract,
                            entryDiligences: x.entryDiligences,
                            registerDate: x.registerDate,
                            thirds: x.thirds,
                            registerEntryOffice: x.registerEntryOffice,
                            csv: x.csv,
                            profile: x.profile
                        }
                    }).toArray() : [],
                isEdit: ((vm.document && vm.document.profiles && AnnexaPermissionsFactory.haveSinglePermissionDual('view_documents',vm.document.profiles)) ? true : false),
                config: {},
                origin: 'document'
            }

            $scope.$on('documentActionButtonExecuted', function (event, args) {
                if(args.button == 'createRegisterEntry'){
                    vm.registerEntriesBox.content.push(args.data);
                } else if(_.contains(['complete', 'returnToDraft', 'publishDocument', 'unpublishDocument'], args.button)) {
                    $state.reload();
                }
            });

                
            var initialState = '';
            vm.dossierTransactionBox = angular.copy(BoxFactory.DossierTransactionBox).initialize('global.literals.related_transactions','global.literals.placeholder_search_dossiers', 'global.literals.advanced_search_dossiers',false,((vm.document && vm.document.profiles && AnnexaPermissionsFactory.haveSinglePermissionDual('view_documents',vm.document.profiles)) ? true : false),((vm.document && vm.document.profiles && AnnexaPermissionsFactory.haveSinglePermissionDual('view_documents',vm.document.profiles)) ? true : false), Language.getActiveColumn());
            vm.dossierTransactionBox.boxDefinition.content = [];
            vm.dossierTransactionBox.boxDefinition.saveSearch = function (dossierTransactions) {
            	var dossierTransactionIds = [];
            	if(dossierTransactions && dossierTransactions.length > 0){
            		dossierTransactionIds = $linq(dossierTransactions).select("x=>x.id").toArray();
            	}
            	DccumentsFactory.newDocumentDossierTransaction(vm.document.id, dossierTransactionIds )
                    .then(function(data) {
                    	if(data){
	                    	if (!vm.dossierTransactionBox.content) {
	                    		vm.dossierTransactionBox.content = [];
	                    	}else{
	                    		vm.dossierTransactionBox.content.length = 0;
	                    	}
	                    	_.forEach(data, function(th){
	                    		vm.dossierTransactionBox.content.push(th.dossierTransaction);
	                    	});
                    	}
                }).catch(function (error) {
                    console.error(error)
                });
            };;
            vm.dossierTransactionBox.boxDefinition.deleteFunction = function (objectId, index) {};
            $scope.$on('annexaBoxDossierTransactionDelete', function (event, args) {
                if(args.origin && args.origin == 'document' && vm.document && vm.document.profiles && AnnexaPermissionsFactory.haveSinglePermissionDual('view_documents',vm.document.profiles)) {
                	DccumentsFactory.deleteDocumentDossierTransaction(vm.document.id, args.removedId)
                    .then(function(data) {
                    	if(data){
                        	if (!vm.dossierTransactionBox.content) {
                        		vm.dossierTransactionBox.content = [];
                        	}else{
                        		vm.dossierTransactionBox.content.length = 0;
                        	}
                        	_.forEach(data, function(th){
                        		vm.dossierTransactionBox.content.push(th.dossierTransaction);
                        	});
                    	}
                }).catch(function (error) {
                    console.error(error)
                });
                }
            });
            vm.dossierTransactionBox.boxDefinition.renderType = 'dossierTransaction-document';
            vm.dossierTransactionBox.boxDefinition.languageColumn = Language.getActiveColumn();
            vm.dossierTransactionBox.boxDefinition.origin = 'document';
            vm.dossierTransactionBox.boxDefinition.content = [];
            if(DccumentsFactory.documentDossiers) {
                vm.dossierTransactionBox.boxDefinition.content = DccumentsFactory.documentDossiers;
            }
            vm.dossierTransactionBox.boxDefinition.isEdit = ((vm.document && vm.document.profiles && AnnexaPermissionsFactory.haveSinglePermissionDual('view_documents',vm.document.profiles)) ? true : false);
            var addTaskSave = function(newTask) {
                if(newTask){
                    if(vm.document.archiveClassification && vm.document.archiveClassification.model) {
                        vm.document.archiveClassification = vm.checkTreeValue(vm.document.archiveClassification.model, undefined);
                    }                                                           

                    _.forEach(vm.document.tasks, function (item, index) {
                        if(!item.document) {
                            vm.document.tasks[index].document = { id: vm.document.id };
                        }
                        if(!item.documents) {
                        	vm.document.tasks[index].documents = [];
                        	vm.document.tasks[index].documents.push(vm.document);
                        }
                    });

                    DccumentsFactory.updateDocument(vm.document)
                    	.then(function(data) {
                    		vm.document.profiles = data.profiles;
                    	}).catch(function (error) {
                        vm.tasksBox.boxDefinition.content.pop();
                    	});                                        
                }
            }
            
            vm.taskBox = {
            	// s'afegeixen aquestos permissos per poder veure els botons i la cerca
                permissions: { new: 'new_document', view: 'view_documents' },
                search: { placeholder: 'global.literals.placeholderSearchTaskBox', advanced: true, saveSearch: addTaskSave },
                content: vm.document.tasks,
                new: {},
                isEdit: true,
                config: {},
                origin: 'annexa-document'
            }
            var thirdIdentificationDocumentTypesFilter = undefined;
            if($rootScope.app.configuration.reg_thirds_permited_identification_document_types.value) {
                thirdIdentificationDocumentTypesFilter = {
                    thirdIdentificationDocumentTypes: $rootScope.app.configuration.reg_thirds_permited_identification_document_types.value
                }
            }
            thirdIdentificationDocumentTypesFilter.searchBox = true;
            thirdIdentificationDocumentTypesFilter.active = true;

            var addThirdSave = function(newThirdId) {
            	DccumentsFactory.newDocumentThird(vm.document.id, newThirdId)
                    .then(function(data) {
                    	if(data){
	                    	if (!vm.thirdBox.content) {
	                    		vm.thirdBox.content = [];
	                    	}else{
	                    		vm.thirdBox.content.length = 0;
	                    	}
	                    	_.forEach(data, function(th){
	                    		vm.thirdBox.content.push(th);
	                    	});
                    	}
                }).catch(function (error) {
                    console.error(error)
                });
            }
            
            $scope.$on('annexaBoxThirdsDelete', function (event, args) {
                if(args.origin && args.origin == 'annexa-document' && vm.document && vm.document.profiles && AnnexaPermissionsFactory.haveSinglePermissionDual('view_documents',vm.document.profiles)) {
                	DccumentsFactory.deleteDocumentThird(vm.document.id,  args.removedId)
                    .then(function(data) {
                    	if(data){
	                    	if (!vm.thirdBox.content) {
	                    		vm.thirdBox.content = [];
	                    	}else{
	                    		vm.thirdBox.content.length = 0;
	                    	}
	                    	_.forEach(data, function(th){
	                    		vm.thirdBox.content.push(th);
	                    	});
                    	}
                }).catch(function (error) {
                    console.error(error)
                });
                }
            });
            
            vm.thirdBox = {
                boxTitle: 'global.literals.thirds',
                permissions: getEditPermissions('thirds'),
                search: {
                    placeholder: 'global.literals.placeholder_search_receiver',
                    advanced: true,
                    saveSearch: ((vm.document && vm.document.profiles && AnnexaPermissionsFactory.haveSinglePermissionDual('view_documents',vm.document.profiles)) ? addThirdSave : undefined),
                    additionalFilter: thirdIdentificationDocumentTypesFilter,
                    noAddmodalOnAdd: true,
                    addedSelect: 'x => x.id',
                    completeAdd: function(){
                    	var self = this;
                    	if(self && self.searchedValue && self.searchedValue.id){
                    		if(vm.thirdBox.search.saveSearch) {
                    			vm.thirdBox.search.saveSearch(self.searchedValue.id);
    	                    }
                            $rootScope.$broadcast('closeAnnexaFormModal', { id: 'modalSearchAdd' });
                    	}
                    }
                },
                content: DccumentsFactory.documentThirds,
                new: {},
                isEdit: ((vm.document && vm.document.profiles && AnnexaPermissionsFactory.haveSinglePermissionDual('view_documents',vm.document.profiles)) ? true : false),
                config: {viewConsentAll:true},
                origin: 'annexa-document'
            }
            
            
            
            vm.proposalBox = {
                    content: DccumentsFactory.documentProposals,
                    config: {},
                    origin: 'annexa-document',
                    isEdit: false,
                    canEdit: false
            }
            
            var addSessionSave = function(newSessions) {
            	var newSessionIds = ((newSessions && newSessions.length > 0)?$linq(newSessions).select("x => x.id").toArray():[]);
            	DccumentsFactory.newDocumentSession(vm.document.id, newSessionIds)
                    .then(function(data) {
                    	if(data){
	                    	if (!vm.sessionBox.content) {
	                    		vm.sessionBox.content = [];
	                    	}else{
	                    		vm.sessionBox.content.length = 0;
	                    	}
	                    	_.forEach(data, function(session){
	                    		vm.sessionBox.content.push(session);
	                    	});
                    	}
                }).catch(function (error) {
                    console.error(error)
                });
            }
            
            $scope.$on('annexaBoxSessionDelete', function (event, args) {
                if(args.origin && args.origin == 'annexa-document' && vm.document && vm.document.profiles && AnnexaPermissionsFactory.haveSinglePermissionDual('view_documents',vm.document.profiles)) {
                	DccumentsFactory.deleteDocumentSession(vm.document.id,  args.removedId)
                    .then(function(data) {
                    	if(data){
	                    	if (!vm.sessionBox.content) {
	                    		vm.sessionBox.content = [];
	                    	}else{
	                    		vm.sessionBox.content.length = 0;
	                    	}
	                    	_.forEach(data, function(th){
	                    		vm.sessionBox.content.push(th);
	                    	});
                    	}
                }).catch(function (error) {
                    console.error(error)
                });
                }
            });
            
            vm.sessionBox = {
            		//permissions: getEditPermissions('sessions'),
            		//search: {
                        //placeholder: 'global.sec.literals.placeholder_search_session',
                        //advanced: true,
                        //saveSearch: ((vm.document && vm.document.profiles && AnnexaPermissionsFactory.haveSinglePermissionDual('view_documents',vm.document.profiles)) ? addSessionSave : undefined),
                        //completeAdd: function(){
                        	//var self = this;
                        	/*if(self && self.searchedValue && self.searchedValue.id){
                        		if(vm.thirdBox.search.saveSearch) {
                        			vm.thirdBox.search.saveSearch(self.searchedValue.id);
        	                    }
                                $rootScope.$broadcast('closeAnnexaFormModal', { id: 'modalSearchAdd' });
                        	}*/
                        //}
                    //},
                    content: DccumentsFactory.documentSessions,
                    config: {},
                    origin: 'annexa-document',
                    isEdit: false
                }
            $scope.$on('annexaBoxTasksDelete', function (event, args) {
                if(args.origin && args.origin == 'annexa-document') {
                	if(vm.document.archiveClassification && vm.document.archiveClassification.model) {
                        vm.document.archiveClassification = vm.checkTreeValue(vm.document.archiveClassification.model, undefined);
                    }
                    
                    DccumentsFactory.updateDocument(vm.document)
                	.then(function(data) {
                		vm.document.profiles = data.profiles;
                	}).catch(function (error) {
                		vm.tasksBox.boxDefinition.content.pop();
                	});                                        
                 }
            });
            
            //endregion

            //region Mètodes

            vm.checkTreeValue = function(value, empty) {
                if(value && value.$selected && value.$selected.id) {
                    return { id: value.$selected.id };
                } else {
                    return empty;
                }
            }

            vm.updateDocument = function (val, prop, reload) {


                var checkSelect = function(value) {
                    if(value && value.id) {
                        return { id: value.id };
                    } else {
                        return {};
                    }
                }

                var doc = angular.copy(vm.document);

                if(doc.archiveClassification) {
                    doc.archiveClassification = vm.checkTreeValue(doc.archiveClassification.model, undefined);
                }

                var fixedValue = val;

                if(prop == 'archiveClassification') {
                    fixedValue = vm.checkTreeValue(val, {});
                } else if(prop == 'profiles') {
                    fixedValue = [];
                    _.forEach(val, function (item) {
                        fixedValue.push({ profile: { id: item.id } });
                    });
                } else if(prop == 'name'){
                    if(!doc || !doc.type || !doc.type.id || !DccumentsFactory.validDocumentName(fixedValue, doc.type.id)){
                        return HelperService.getErrorMessageValidatePattern();
                    }
                }

                doc[prop] = fixedValue;

                $rootScope.loading(true);
                DccumentsFactory.updateDocument(doc)
                    .then(function(data) {
                    	vm.document.profiles = data.profiles;
                    	if(reload) {
                    		$state.reload();
                    	}
                    	
                        $rootScope.loading(false);
                    }).catch(function (error) {
                        $rootScope.loading(false);
                });
            };

            $scope.$on('documentActionButtonExecuting', function (event, args) {
                switch (args.button) {
                    case 'editOnline':
                        vm.editDocument(true);
                        break;
                }
            });

            $scope.$on('SendToSignModalFinished', function(event, args) {
                if(args.data && Array.isArray(args.data) && args.data.length > 0) {
                    vm.document = args.data[0];
                    vm.document.content = vm.oldDocumentContent;
                    $rootScope.$broadcast('docContentLoaded');
                    $state.reload();
                }
            });

            vm.editDocument = function (value) {
                if(!value) {
                    var oldContent = vm.document.content;

                    vm.document.content = '';

                    DccumentsFactory.getDocContent(vm.document.id)
                        .then(function(data) {
                            vm.document.content = data;
                            $rootScope.$broadcast('docContentLoaded');
                        }).catch(function (error) {
                            vm.document.content = oldContent;
                    });
                }

                vm.isEditing = value;
            }

            //endregion

            vm.editDocument(false);

            var fromState = $rootScope.previousState;
            var fromParams = $rootScope.previousStateParams;

            if(fromState && fromState.name && fromParams && fromParams.type) {
                angular.element('[ui-sref="annexa.doc.documents.type({type: ' + fromParams.type + '})"]').parent().addClass('active');
            } else {
                angular.element('[ui-sref="annexa.doc.documents.all"]').parent().addClass('active');
            }
        }]
    })