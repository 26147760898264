/**
 * Created by osirvent on 25/04/2017.
 */
angular
    .module('annexaApp')
    .component('annexaDocumentPublish', {
        templateUrl: './components/doc/annexa-document-publish/annexa-document-publish.html',
        controller: ['$q', '$filter', '$http', 'Language', 'RestService', '$rootScope', '$scope', 'ErrorFactory', 'AnnexaFormlyFactory', 'globalModals', 'DialogsFactory', 'AnnexaModalFactory', function ($q, $filter, $http, Language, RestService, $rootScope, $scope, ErrorFactory, AnnexaFormlyFactory, globalModals, DialogsFactory, AnnexaModalFactory) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();

            vm.model = {}
            vm.publishPlugins = [];

            $http({
                url: './api/plugin/publish/list'
            }).then(function(data) {
                vm.publishPlugins = [];
                _.forEach(data.data, function (value) {
                    value.pluginActive = false;
                    if(value.availableStates && value.availableStates.length > 0){
                        value.pluginActive = $linq(value.availableStates).contains(vm.document.docStatus);
                    }
                    if(value.pluginActive) {
                        value.checked = false;
                        value.selected = false;
                        $http({
                            url: './api/plugin/publish/target/'+value.target+'/document/'+vm.document.id
                        }).then(function(data) {
                            if(data.data && data.data.length == 7){
                                value.checked = data.data[0];
                                value.unpublish = data.data[1];
                                value.cancelPublish = data.data[2];
                                value.updatePublish = data.data[3];
                                value.checkDocument = data.data[4];
                                value.checkStatus = data.data[5];
                                value.getValuesFunction = data.data[6];
                                value.selected = false;
                                if(value.unpublish){
                                    value.unpublishFunction = function(){
                                        $http({
                                            url: './api/plugin/publish/target/'+value.target+'/document/'+vm.document.id+'/unpublish',
                                            method: 'GET'
                                        }).then(function(data) {
                                        	if(data && data.data){
	                                        	value.unpublish = false;
	                                        	value.checkDocument = true;
	                                            $rootScope.$broadcast('documentActionButtonExecuted', {button:'unpublishDocument', documentId:vm.document.id});
                                        	}else{
                                        		DialogsFactory.error($filter('translate')('global.errors.unknownUpdate'));
                                        	}
                                        }).catch(function(error){
                                        	DialogsFactory.error($filter('translate')('global.errors.unknownUpdate'));
                                        });
                                    }
                                }
                                if(value.updatePublish){
                                	value.updateFunction = function(){
                                		$http({
                                            url: './api/plugin/publish/target/'+value.target+'/document/'+vm.document.id+'/findpublish',
                                            method: 'GET'
                                        }).then(function(data) {
                                            if(data && data.data){
                                            	var actualEndDate = undefined;
                                            	var favorite = undefined;
                                            	var publishData = angular.copy(JSOG.decode(data.data));
                                            	if(publishData && publishData.parameter){
                                            		var dateactual = $linq(angular.fromJson(publishData.parameter)).firstOrDefault(undefined,"x => x.id == 'dataFiPublicacio'");
                                            		if(dateactual && dateactual.value){
                                            			actualEndDate = new Date(dateactual.value.replaceAll('/','-').replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")); 
                                            		}
                                            		var destacat = $linq(angular.fromJson(publishData.parameter)).firstOrDefault(undefined,"x => x.id == 'destacat'");
                                            		if(destacat && (destacat.value == true || destacat.value == 'true')){
                                            			favorite = true;
                                            		}else{
                                            			favorite = false;
                                            		}
                                            	}
                                            	var modalModify = angular.copy(globalModals.modifyPublishDocument);
                                        	    modalModify.annexaFormly.model = {modal_body:{favorite_true:favorite, endPublishDate:actualEndDate}}
                                        	    modalModify.data = { document: vm.document.id, target: value.target, publish: JSOG.decode(data.data)}
                                        	    modalModify.submitLabel = 'global.literals.publish';
                                        	    modalModify.alerts = [];
                                                var submitFunction = function () {
                                                	var model = this.annexaFormly.model.modal_body;
                                                    var selfModify = this;
                                                    selfModify.alerts.length = 0;
                                                    if(selfModify.data && selfModify.data.publish && selfModify.data.publish.parameter){
                                                    	var parameters = angular.fromJson(selfModify.data.publish.parameter);
                                                    	if(parameters && parameters.length > 0){
                                                    		function pad(s) { return (s < 10) ? '0' + s : s; }
                                                    		_.forEach(parameters, function(parameter){
                                                    			if(parameter.id == 'dataFiPublicacio'){
                                                    				if(model.endPublishDate){
                                                    					parameter.value = [pad(model.endPublishDate.getDate()), pad(model.endPublishDate.getMonth()+1), model.endPublishDate.getFullYear()].join('/');                                                    					
                                                    				}else{
                                                    					parameter.value = undefined;
                                                    				}
                                                    			}else if(parameter.id == 'destacat'){
                                                    				parameter.value = model.favorite_true;
                                                    			}
                                                    		})
                                                    		selfModify.data.publish.parameter = angular.toJson(parameters);
	                                                    	$http({
			                                                    url: './api/plugin/publish/target/'+value.target+'/document/'+vm.document.id+'/update',
			                                                    method: 'PUT',
			                                                    data: JSOG.encode(selfModify.data.publish)
			                                                }).then(function(data) {
			                                                    $rootScope.$broadcast('documentActionButtonExecuted', {button:'unpublishDocument', documentId:vm.document.id});
			                                                    selfModify.close();
			                                                }).catch(function(error){
			                                                	DialogsFactory.error($filter('translate')('global.errors.unknownUpdate'));
			                                                });
                                                    	}else{
                                                    		DialogsFactory.error($filter('translate')('global.errors.unknownUpdate'));
                                                    	}
                                                    }else{
                                                    	DialogsFactory.error($filter('translate')('global.errors.unknownUpdate'));
                                                    }
                                                }
                                                AnnexaFormlyFactory.showModal('modalModifyPublish', modalModify, submitFunction);
                                            }
                                        }).catch(function(error){
                                        	DialogsFactory.error($filter('translate')('global.errors.unknownUpdate'));
                                        });
                                    }
                                }
                                if(value.cancelPublish){
                                	value.cancelFunction = function(){
                                        $http({
                                            url: './api/plugin/publish/target/'+value.target+'/document/'+vm.document.id+'/cancel',
                                            method: 'POST'
                                        }).then(function(data) {
                                            if(data && data.data){
                                            	value.checked = false;
                                            	value.selected = false;
                                            	$rootScope.$broadcast('documentActionButtonExecuted', {button:'unpublishDocument', documentId:vm.document.id});
                                            }else{
                                            	DialogsFactory.error($filter('translate')('global.errors.unknownUpdate'));
                                            }
                                        }).catch(function(error){
                                        	DialogsFactory.error($filter('translate')('global.errors.unknownUpdate'));
                                        });
                                    }
                                }
                        		value.checkDiligenceFunction = function(){
                            		var modalModify = angular.copy(globalModals.createDiligencePublish);
                            	    modalModify.annexaFormly.model = {modal_body:{}}
                            	    modalModify.data = { document: vm.document.id, target: value.target}
                            	    modalModify.submitLabel = 'global.literals.save';
                            	    modalModify.alerts = [];
                                    var submitFunction = function () {
                                    	var model = this.annexaFormly.model.modal_body;
                                        var selfModify = this;
                                        selfModify.alerts.length = 0;
                                        if(selfModify.data && selfModify.data.document && selfModify.data.target){
                                    		$http({
                                                url: './api/plugin/publish/target/'+value.target+'/document/'+vm.document.id+'/createDiligence',
                                                method: 'POST',
                                                data: JSOG.encode(model),
                                                responseType: 'arraybuffer'
                                            }).then(function(data) {
                                        		var headers = data.headers()
						                        var retDownload = {
						                            file: undefined,
						                            fileName: undefined,
						                            linkElement: undefined,
						                            clickEvent: undefined
						                        }
					
						                        var contentType = headers['content-type'];
						                        retDownload.file = new Blob([data.data], { type: contentType });
						                        retDownload.fileName = headers['content-disposition'].replace('attachment; filename="','').replace('"', '');
												
						                        try {
						                            if (window.navigator.msSaveOrOpenBlob) {
						                                window.navigator.msSaveOrOpenBlob(retDownload.file, retDownload.fileName);
						                            } else {
						                                retDownload.linkElement = document.createElement('a');
						                                var url = window.URL.createObjectURL(retDownload.file);
						                                retDownload.linkElement.setAttribute('href', url);
						                                retDownload.linkElement.setAttribute("download", retDownload.fileName);
						                                retDownload.clickEvent = new MouseEvent("click", {
						                                    "view": window,
						                                    "bubbles": true,
						                                    "cancelable": false
						                                });
						                                retDownload.linkElement.dispatchEvent(retDownload.clickEvent);
						                            }
						                            $rootScope.loading(false);
						                        } catch(e) {
						                            $rootScope.loading(false);
						                        }
                                                $rootScope.$broadcast('documentActionButtonExecuted', {button:'createDiligencePlugin', documentId:vm.document.id});
                                                selfModify.close();
                                            }).catch(function(error){
                                            	DialogsFactory.error($filter('translate')('global.errors.unknownNew'));
                                            });
                                        }else{
                                        	DialogsFactory.error($filter('translate')('global.errors.unknownNew'));
                                        }
                                    }
                                    AnnexaFormlyFactory.showModal('modalCreateDiligencePublish', modalModify, submitFunction);
                                }
                            	value.checkStateFunction = function(){
                                    $http({
                                        url: './api/plugin/publish/target/'+value.target+'/document/'+vm.document.id+'/checkState',
                                        method: 'GET'
                                    }).then(function(data) {
                                    	if(data && data.data && data.data.length > 0){
                                    		var modal = angular.copy(globalModals.showStatesPublish);
                                    		modal.data = { statuses:JSOG.decode(data.data)}
                                    		modal.alerts = [];
                                            AnnexaModalFactory.showModal('modalShowStatesPublish', modal);
                                    	}
                                    }).catch(function(error){
                                    	DialogsFactory.error($filter('translate')('global.errors.unknownUpdate'));
                                    });
                                }
                                if(value.getValuesFunction){
                            	    $http({
                                        url: './api/plugin/publish/target/'+value.target+'/values?documentId='+vm.document.id+((vm.dossierId)?"&dossierTransactionId="+vm.dossierId:""),
                                        method: 'GET'
                                    }).then(function(data) {
                                    	if(data && data.data){
                                        	vm.model[value.target] = {};
                                        	var dataAux = JSOG.decode(data.data);
                                        	_.forEach(Object.entries(dataAux), function(prop){
                                        		if(prop[0] != 'notLinkedDossierIdAndDocumentId'){
                                        			vm.model[value.target][prop[0]] = prop[1];
                                        		}
                                        	});
                                        	if(dataAux.notLinkedDossierIdAndDocumentId){
                                        		_.forEach(value.parameters, function(param){
                                        			if(param.linked == 'notLinkedDossierIdAndDocumentId'){
                                        				param.optionsUrl = angular.fromJson(dataAux.notLinkedDossierIdAndDocumentId);
                                        			}
                                        		});
                                        	}
                                    	}
                                    }).catch(function(error){
                                   	DialogsFactory.error($filter('translate')('global.errors.unknownUpdate'));
                                    });
                                }
                            }else{
                                value.checked = false;
                                value.selected = false;
                                value.unpublish = false;
                            }
                        }).catch(function(error){
                            value.checked = false;
                            value.selected = false;
                            value.unpublish = false;
                        });
                        vm.model[value.target] = undefined;
                        value.parameters = angular.fromJson(value.parameters);
                        _.forEach(value.parameters, function (val, index) {
                            if (val.frontendType != 'INPUT' && val.frontendType != 'TEXTAREA' && val.frontendType != 'JSON') {
                                if(val.linked != 'notLinkedDossierIdAndDocumentId'){
                                	value.parameters[index].optionsUrl = './api/plugin/publish/list_values/' + value.target + '/' + val.id;
                                	value.parameters[index].optionOnSelect = function (id, $item) {
	                                    var linkedParameters = $linq(value.parameters).where("x => x.linked == '" + id + "'").toArray();
	
	                                    _.forEach(linkedParameters, function (lp) {
	                                        var indexParameter = $linq(value.parameters).indexOf("x => x.id == '" + lp.id + "'");
	
	                                        if (indexParameter != -1) {
	                                            if (!vm.model[value.target]) {
	                                                vm.model[value.target] = {};
	                                            }
	                                            vm.model[value.target][lp.id] = undefined;
	                                            value.parameters[indexParameter].optionsUrl = './api/plugin/publish/list_values/' + value.target + '/' + lp.id + '/' + (($item && $item.id)?$item.id:$item);
	                                        }
	                                    })
	                                }
                                }else{
                                	value.parameters[index].optionOnSelect = function (id, $item) {
                                	}
                                }
                            } else {
                                value.parameters[index].optionsUrl = undefined;
                                if (!vm.model[value.target]) {
                                    vm.model[value.target] = {};
                                }
                                vm.model[value.target][val.id] = undefined;
                            }
                            value.parameters[index].showFunction = function(){
                                if(val.modules && val.modules.length > 1){
                                    var param = $linq(value.parameters).where("x => x.id == '" + val.modules[0] + "'").toArray();
                                    if(param && param.length > 0){
                                        var trobat = false;
                                        for(var i=1; i<val.modules.length; i++) {
                                            if (vm.model && vm.model[value.target] && vm.model[value.target][param[0].id] && vm.model[value.target][param[0].id] == val.modules[i]) {
                                                trobat = true;
                                            }
                                        }
                                        if(!trobat) {
                                        	vm.model[value.target][val.id] = undefined;
                                        	if(this.actualModel) this.actualModel = undefined;
                                        }
                                        return trobat;
                                    }
                                    return true;
                                }else{
                                    return true;
                                }
                            }
                        })
                    }
                    vm.publishPlugins.push(value);
                })
            }).catch(function (error) {
                vm.publishPlugins = [];
            });

            vm.selectPlugin = function($event, index) {
                var checkbox = $event.target;

                vm.publishPlugins[index].selected = checkbox.checked;
            }

            $scope.$on('annexaCustomFieldComponentModelChanged', function (event, args) {
                if(args.plugin){
                    if(!vm.model[args.plugin]){
                        vm.model[args.plugin] = {};
                    }
                    vm.model[args.plugin][args.key] = args.model;
                }

            });

            var isPluginModelValid = function(target) {

            }
            this.$onInit = function () {
                vm.submitFunction = function () {
                    var self = this;
                    var havePlugins = false;
                    _.forEach(vm.publishPlugins, function (plugin) {
                        if(plugin.selected) {
                            havePlugins = true;
                            var a = vm;
                            var publishDocument = {
                                document: {id:vm.document.id},
                                target: plugin.target,
                                published: false
                            };
                            if(vm.dossierId) {
                                publishDocument.originType = 'DOSSIER';
                                publishDocument.originId = vm.dossierId;
                            } else {
                                publishDocument.originType = 'NONE';
                            }
                            var parameters = [];
                            _.forEach(plugin.parameters, function (value) {
                                var parameter = {id:value.id, value:vm.model[plugin.target][value.id]};
                                if(parameter.value && parameter.value.id){
                                    parameter.value = parameter.value.id;
                                }
                                if(parameter.value instanceof Date){
                                	parameter.value = $filter('date')(parameter.value, 'dd/MM/yyyy');
                                }
                                parameters.push(parameter);
                            });
                            publishDocument.parameter = angular.toJson(parameters);
                            RestService.insert('./api/plugin/publish', publishDocument).then(function (data) {
                                self.close();
                                $rootScope.$broadcast('documentActionButtonExecuted', {button:'publishDocument', data:data.document});
                            }).catch(function (error) {
                            	self.alerts.push({ msg: ErrorFactory.getErrorMessage('publish', 'do', error) });
                            })
                        }
                    });
                    if(!havePlugins){
                        self.close();
                    }
                }
            }
        }],
        bindings: {
            document: '=',
            dossierId: '@?',
            submitFunction: '='
        }
    })
    .component('annexaDocumentsPublish', {
        templateUrl: './components/doc/annexa-document-publish/annexa-document-publish.html',
        controller: ['$q', '$filter', '$http', 'Language', 'RestService', '$rootScope', '$scope', 'ErrorFactory', function ($q, $filter, $http, Language, RestService, $rootScope, $scope, ErrorFactory) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();

            vm.model = {};
            vm.publishPlugins = [];
            var getAvailableDocuments = function(documents, availableStates){
                var available = 0;
                if(documents && availableStates) {
                    _.forEach(documents, function(document){
                        if($linq(availableStates).contains(document.docStatus)){
                            available++;
                        }
                    });
                }
                return available;
            };
            $http({
                url: './api/plugin/publish/list'
            }).then(function(data) {
                vm.publishPlugins = [];
                _.forEach(data.data, function (value) {
                    value.pluginActive = false;
                    value.availables = getAvailableDocuments(vm.documents, value.availableStates );
                    if(value.availables > 0){
                        value.pluginActive = true;
                        value.checked = false;
                        value.selected = false;
                        $http({
                            url: './api/plugin/publish/target/'+value.target+'/documents/',
                            method: 'POST',
                            data: JSOG.encode({"documentsId": $linq(vm.documents).select("x=>x.id").toArray()})
                        }).then(function(data) {
                            if(data.data && data.data.length == 3){
                                value.checked = data.data[0];
                                value.unpublish = data.data[1];
                                value.pluginActive = data.data[2];
                                value.selected = false;
                                if(value.unpublish && value.pluginActive){
                                    value.unpublishFunction = function(){
                                        $http({
                                            url: './api/plugin/publish/target/'+value.target+'/documents/unpublish',
                                            method: 'POST',
                                            data: JSOG.encode({"documentsId": $linq(vm.documents).select("x=>x.id").toArray()})
                                        }).then(function(data) {
                                            value.checked = false;
                                            value.selected = false;
                                            $rootScope.$broadcast('documentActionButtonExecuted', {button:'unpublishDocument'});
                                        }).catch(function(error){
                                            var a = 0;
                                        });
                                    }
                                }
                                
                            }else{
                                value.checked = false;
                                value.selected = false;
                                value.unpublish = false;
                                value.canMultiDoc = false;
                            }
                        }).catch(function(error){
                            value.checked = false;
                            value.selected = false;
                            value.unpublish = false;
                            value.canMultiDoc = false;
                        });
                        value.parameters = angular.fromJson(value.parameters);
                        vm.model[value.target] = undefined;
                        _.forEach(value.parameters, function (val, index) {
                            if (val.frontendType != 'INPUT' && val.frontendType != 'TEXTAREA' && val.frontendType != 'JSON') {
                                value.parameters[index].optionsUrl = './api/plugin/publish/list_values/' + value.target + '/' + val.id;
                                value.parameters[index].optionOnSelect = function (id, $item) {
                                    var linkedParameters = $linq(value.parameters).where("x => x.linked == '" + id + "'").toArray();

                                    _.forEach(linkedParameters, function (lp) {
                                        var indexParameter = $linq(value.parameters).indexOf("x => x.id == '" + lp.id + "'");

                                        if (indexParameter != -1) {
                                            if (!vm.model[value.target]) {
                                                vm.model[value.target] = {};
                                            }
                                            vm.model[value.target][lp.id] = undefined;
                                            value.parameters[indexParameter].optionsUrl = './api/plugin/publish/list_values/' + value.target + '/' + lp.id + '/' + (($item && $item.id)?$item.id:$item);
                                        }
                                    })
                                }
                            } else {
                                value.parameters[index].optionsUrl = undefined;
                                if (!vm.model[value.target]) {
                                    vm.model[value.target] = {};
                                }
                                vm.model[value.target][val.id] = undefined;
                            }
                            value.parameters[index].showFunction = function(){
                                if(val.modules && val.modules.length > 1){
                                    var param = $linq(value.parameters).where("x => x.id == '" + val.modules[0] + "'").toArray();
                                    if(param && param.length > 0){
                                        var trobat = false;
                                        for(var i=1; i<val.modules.length; i++) {
                                            if (vm.model && vm.model[value.target] && vm.model[value.target][param[0].id] && vm.model[value.target][param[0].id] == val.modules[i]) {
                                                trobat = true;
                                            }
                                        }
                                        return trobat;
                                    }
                                    return true;
                                }else{
                                    return true;
                                }
                            }
                        })
                    }
                    vm.publishPlugins.push(value);
                })
            }).catch(function (error) {
                vm.publishPlugins = [];
            });

            vm.selectPlugin = function($event, index) {
                var checkbox = $event.target;

                vm.publishPlugins[index].selected = checkbox.checked;
            }

            $scope.$on('annexaCustomFieldComponentModelChanged', function (event, args) {
                if(args.plugin){
                    if(!vm.model[args.plugin]){
                        vm.model[args.plugin] = {};
                    }
                    vm.model[args.plugin][args.key] = args.model;
                }

            });

            var isPluginModelValid = function(target) {

            }
            this.$onInit = function () {
                vm.submitFunction = function () {
                    var self = this;
                    var havePlugins = false;
                    _.forEach(vm.publishPlugins, function (plugin) {
                        if(plugin.selected) {
                            havePlugins = true;
                            var a = vm;
                            var publishDocuments = [];
                            var parameters = [];
                            _.forEach(plugin.parameters, function (value) {
                                var parameter = {id:value.id, value:vm.model[plugin.target][value.id]};
                                if(parameter.value && parameter.value.id){
                                    parameter.value = parameter.value.id;
                                }
                                parameters.push(parameter);
                            });

                            _.forEach(vm.documents, function(document){
                                var publishDocument = {
                                    document: {id:document.id},
                                    target: plugin.target,
                                    published: false
                                };
                                if(vm.dossierId) {
                                    publishDocument.originType = 'DOSSIER';
                                    publishDocument.originId = vm.dossierId;
                                } else {
                                    publishDocument.originType = 'NONE';
                                }
                                publishDocument.parameter = angular.toJson(parameters);
                                publishDocuments.push(publishDocument);
                            });
                            RestService.insert('./api/plugin/publish/documents', publishDocuments).then(function (data) {
                                self.close();
                                $rootScope.$broadcast('documentActionButtonExecuted', {button:'publishDocument', data:data.document});
                            }).catch(function (error) {
                            	self.alerts.push({ msg: ErrorFactory.getErrorMessage('publish', 'do', error) });
                            })
                        }
                    });
                    if(!havePlugins){
                        self.close();
                    }
                }
            }
        }],
        bindings: {
            documents: '=',
            dossierId: '@?',
            submitFunction: '='
        }
    })