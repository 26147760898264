angular
    .module('annexaApp')
    .component('annexaScannerDocuments',{
        templateUrl: './components/doc/annexa-scanner-documents/annexa-scanner-documents.html',
        controller: ['$scope', 'DccumentsFactory', '$filter', function($scope, DccumentsFactory, $filter) {
            var vm = this;
            var completed = 0;

            vm.processState = 'global.literals.fromScannerUploadDocuments';

            var checkCompleted = function() {
                completed++;

                if(completed == vm.documents.length) {
                    vm.processState = 'global.literals.fromScannerUploadDocumentsCompleted';
                    angular.element('#fromScannerWizardModal .modal-footer button').removeClass('disabled');
                }
            }

            $scope.$on('fromScannerUploadDocuments', function (event, args) {
                vm.processState = 'global.literals.fromScannerUploadDocumentsStart';
                angular.element('#fromScannerWizardModal .wizard-buttons').hide();
                angular.element('#fromScannerWizardModal .modal-footer button').text($filter('translate')('global.literals.close'));
                angular.element('#fromScannerWizardModal .modal-footer button').addClass('disabled');
                var conjuntFields = undefined;
                if(args && args.modal && args.modal.wizard && args.modal.wizard.steps){
                	var step = $linq(args.modal.wizard.steps).firstOrDefault(undefined, "x => x.key == 'documentAllInOne'");
                	if(step && step.annexaFormly && step.annexaFormly.fields && step.annexaFormly.fields.length > 0 && step.annexaFormly.fields[0].fieldGroup){
                		conjuntFields = step.annexaFormly.fields[0].fieldGroup;
                	}
                }
                _.forEach(vm.documents, function (doc, key) {
                    vm.documents[key].procState = 'uploading';
                    var documentFields = undefined
                    if(args && args.modal && args.modal.wizard && args.modal.wizard.steps){
                    	var step = $linq(args.modal.wizard.steps).firstOrDefault(undefined, "x => x.key == 'selectDocumentsUpload'");
                    	if(step && step.annexaFormly && step.annexaFormly.model && step.annexaFormly.model.uploadType == 2 && conjuntFields){
                    		documentFields = angular.copy(conjuntFields);
                    	}else{
                    		if(doc && doc.fileName && doc.fileName.includes("'")) {
                    			var doc_filename = '';
                    				doc_filename = doc.fileName.replaceAll("'","\\'");
                    				doc.fileName = doc_filename;
                    		}
                    		step = $linq(args.modal.wizard.steps).firstOrDefault(undefined, "x => x.key == '"+doc.fileName+"'");
                        	if(step && step.annexaFormly && step.annexaFormly.fields && step.annexaFormly.fields.length > 0 && step.annexaFormly.fields[0].fieldGroup){
                        		documentFields = angular.copy(step.annexaFormly.fields[0].fieldGroup);
                        	}
                    	}
                    }
                    DccumentsFactory.createNewDocument(documentFields, doc, doc.module)
                        .then(function(data) {
                            checkCompleted();

                            vm.documents[key].procState = 'complete';
                            vm.documents[key].docData = data;
                        }).catch(function(error) {
                            checkCompleted();

                            vm.documents[key].procState = 'error';
                            vm.documents[key].error = $filter('translate')(error.msg);
                    })
                });
            })
        }],
        bindings: {
            documents: '=',
            module: '='
        }
    })