angular
    .module('annexaApp')
    .component('annexaBoxProposalDocuments',{
        templateUrl: './components/doc/annexa-box-proposal-documents/annexa-box-proposal-documents.html',
        controller:['$scope', 'Language', 'GlobalDataFactory','$rootScope','DccumentsFactory', 'AnnexaDocumentActionsButtonFactory', '$filter',  function($scope, Language, GlobalDataFactory, $rootScope, DccumentsFactory, AnnexaDocumentActionsButtonFactory, $filter) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            
            vm.getDocStatusTitle = function (document) {
                return 'global.documentState.' + document.docStatus;
            }

            vm.getDocStatusIcon = function (document) {
                switch (document.docStatus) {
                    case 'NEW':
                        return 'fa fa-circle-o text-blue fa-lg';
                        break;
                    case 'NEW_AND_ATTACHMENT':
                        return 'fa fa-adjust text-blue fa-lg';
                        break;
                    case 'PENDING_SIGN':
                        return 'fa fa-circle text-blue fa-lg';
                        break;
                    case 'SIGNED':
                        return 'fa fa-circle text-success fa-lg';
                        break;
                    case 'REJECTED':
                        return 'fa fa-circle text-danger fa-lg';
                        break;
                    case 'COMPLETE':
                        return 'fa fa-dot-circle-o text-success fa-lg v-t';
                        break;
                    case 'ARCHIVED':
                        return 'fa fa-circle-o text-success fa-lg v-t';
                        break;

                }
            }
            
            vm.documentIsExpired = function(expirationDate){
				var documentIsExpired = false;
				if(expirationDate){
					if(expirationDate instanceof Date){
						if(expirationDate < new Date()){
							documentIsExpired = true;
						}
					}else {
						if(new Date(expirationDate) < new Date()){
							documentIsExpired = true;
						}
					}
				}
				return  documentIsExpired;
			}
            
            vm.viewDocument = function(document){
                if(document) {
                    $rootScope.loading(true);
                    var windowReference = window.open();
                    DccumentsFactory.getDocContent(document.id)
                        .then(function(data) {
                            $rootScope.loading(false);
                            windowReference.location = data;
                    }).catch(function (error) {
                        $rootScope.loading(false);
                        windowReference.close();
                    });
                }
            }
            vm.authCopyDocument = function(document){
                 $rootScope.loading(true);
                 var windowReference = window.open();
                 DccumentsFactory.getAuthCopyContent(document.id)
                 .then(function(data) {
                     $rootScope.loading(false);
                     windowReference.location = data;
                 }).catch(function (error) {
                     $rootScope.loading(false);
                     windowReference.close();
                 });
            }
            vm.getTitleDocumentView = function(type){
                var titleDocView = "";
                if(type == "authCopy"){
                    titleDocView = "[" + $filter('translate')('global.literals.seeAuthCopy') + "]";
                }else{
                    titleDocView = "[" + $filter('translate')('global.literals.seeDocument') + "]";
                }
                
                return titleDocView;
            }
            vm.showViewDocument = function(document){
                if(AnnexaDocumentActionsButtonFactory.isDocumentConvertibleToPdf(document, true)){
                    return true;
                }
                return false;
            }
            
            this.$onInit = function() {
                vm.docModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.doc.documents.all'").toArray();

                if(!vm.boxTitle) {
                    vm.boxTitle = 'global.literals.documents';
                }

                if(!vm.permissions) {
                    vm.permissions = {};
                }

            	if(!vm.module){
            		vm.module = 'SEC';
            	}
            }
        }],
        bindings: {
            boxTitle: '@?',
            module: '=?',
            permissions: '=',
            content: '=',
            emptyText: '@',
            config: '=',
            origin: '@'
        }
    })